import * as React from "react"
import SharedHeader from "../components/general/components/SharedHeader"
import WithdrawalListPage from "../components/robot/pages/WithdrawalListPage"


// markup
const WithdrawalList = () => {
    return (
        <React.Fragment>
            <SharedHeader />
            <WithdrawalListPage />
        </React.Fragment>
    )
}

export default WithdrawalList
