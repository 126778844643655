import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, message, Modal, Spin, Tabs } from "antd";
import { navigate } from "gatsby";
import { ceil, cloneDeep, isEmpty } from "lodash";
import get from "lodash.get";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { connect } from "react-redux";
import { feathers } from "../../../api/feathers_rest";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getWithdrawTransaction from "../../../newApi/asset/withdrawal/getWithdrawTransaction";
import { triggerModal } from "../../../redux/actions/app-actions";
import {
  updateTabActiveId,
  updateWithdrawalActiveId,
} from "../../../redux/actions/product-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { routes } from "../../../route";
import { formatNumber } from "../../../utilities/common-function";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
import { getFormatedDate } from "../config";

// markup

const WithdrawalList = (props) => {
  const { t } = useTranslation();
  const accessKey = get(props.user, "accessKey");
  // const location = useLocation();
  // const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [withdrawList, setWithdrawList] = useState([]);
  const [appeal, setAppeal] = useState(false);
  const [appealData, setAppealData] = useState({});
  // const [showError, setShowError] = useState();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [approvedTotal, setAprrovedTotal] = useState(0);
  const [rejectedTotal, setRejectedTotal] = useState(0);
  const [approvedPage, setApprovePage] = useState(1);
  const [rejectedPage, setRejectedPage] = useState(1);
  const [approvedList, setApprovedList] = useState([]);
  const [rejectedList, setRejectedList] = useState([]);

  const isAuthenticated = get(props, "user.isAuthenticated");
  const user = get(props, "user.user");
  const PAGE_SIZE = 50;

  const getData = (skip) => {
    if (isEmpty(get(user, "referralCode")) || !isAuthenticated) {
      message.error(t("userNotFound", sourceKey.asset));
    } else {
      getWithdrawTransaction(
        PAGE_SIZE,
        skip,
        {
          // userId: get(user, "referralCode"),
          status: 0,
          sort: { appealAt: -1 },
        },
        accessKey
      )
        .then((res) => {
          // console.log("res",res);
          if (res.data) {
            setWithdrawList(get(res, "data"));
          }
          if (get(res, "total")) {
            setTotal(get(res, "total"));
          }
        })
        .catch((err) => {
          message.error(err);
        });
    }
  };

  const getApprovedData = (skip) => {
    if (isEmpty(get(user, "referralCode")) || !isAuthenticated) {
      message.error(t("userNotFound", sourceKey.asset));
    } else {
      getWithdrawTransaction(
        PAGE_SIZE,
        skip,
        {
          userId: get(user, "referralCode"),
          status: 2,
          sort: { appealAt: -1 },
        },
        accessKey
      )
        .then((res) => {
          if (res.data) {
            setApprovedList(get(res, "data"));
          }
          if (get(res, "total")) {
            setAprrovedTotal(get(res, "total"));
          }
        })
        .catch((err) => {
          message.error(err);
        });
    }
  };

  const getRejectedData = (skip) => {
    if (isEmpty(get(user, "referralCode")) || !isAuthenticated) {
      message.error(t("userNotFound", sourceKey.asset));
    } else {
      getWithdrawTransaction(
        PAGE_SIZE,
        skip,
        {
          userId: get(user, "referralCode"),
          status: 1,
          sort: { appealAt: -1 },
        },
        accessKey
      )
        .then((res) => {
          if (res.data) {
            setRejectedList(get(res, "data"));
          }
          if (get(res, "total")) {
            setRejectedTotal(get(res, "total"));
          }
        })
        .catch((err) => {
          message.error(err);
        });
    }
  };

  useEffect(() => {
    getData((page - 1) * PAGE_SIZE);
  }, [page]);

  useEffect(() => {
    getApprovedData((approvedPage - 1) * PAGE_SIZE);
  }, [approvedPage]);

  useEffect(() => {
    getRejectedData((rejectedPage - 1) * PAGE_SIZE);
  }, [rejectedPage]);

  const _renderType = (type, item) => {
    switch (type) {
      case 2:
        return (
          <React.Fragment>
            {/*  <div className="flex-shrink-0 w-full flex justify-center">
              审核通过 Approved
            </div> */}
          </React.Fragment>
        );
      case 1:
        return (
          <React.Fragment>
            {/* <div className=" flex-shrink-0 w-full flex justify-center">
              审核拒绝 Rejected
            </div> */}
            <div className=" flex-shrink-0 w-full flex justify-center">
              {get(item, "remark")}
            </div>
          </React.Fragment>
        );
      //return "审核拒绝 Rejected";
      case 0:
        // return "审核中 Pending";
        break;

      default:
        break;
    }
  };

  function getStatusToNumber(status) {
    //console.log('status value',status);
    if (status.toLowerCase() === "pending") {
      return 0;
    } else if (status.toLowerCase() === "rejected") {
      return 1;
    } else {
      return 2;
    }
  }

  const appealWithdrawal = (data) => {
    //console.log("appeal", data);
    let id = get(data, "_id");
    let date_now = new Date();
    feathers("withdraw-transactions", accessKey)
      .patch(id, { appealAt: date_now.toISOString(), isAppeal: 1 })
      .then((res) => {
        message.success(t("appealSent", sourceKey.asset), 3);
        setAppeal(false);
        getData();
      })
      .catch((err) => {
        message.error("Something Went Wrong.");
      });
  };

  const withdrawalDispaly = (data, status) => {
    let statusNumber = getStatusToNumber(status);
    let list_data = [];

    if (!isEmpty(data)) {
      list_data = cloneDeep(data);
    }

    let total_pages = 0;
    let current_page = 0;

    if (statusNumber === 0) {
      total_pages = ceil(total / PAGE_SIZE);
      current_page = page;
    } else if (statusNumber === 2) {
      total_pages = ceil(approvedTotal / PAGE_SIZE);
      current_page = approvedPage;
    } else {
      total_pages = ceil(rejectedTotal / PAGE_SIZE);
      current_page = rejectedPage;
    }

    return (
      <>
        <Scrollbars style={{ height: 600 }}>
          <div className="p-4" style={{ minHeight: 200 }}>
            {list_data.map((i, index) => {
              const appealAt = new Date(get(i, "appealAt"));
              const transactionDate = new Date(get(i, "transactionDate"));
              let appeal_result = false;

              const diffInMs = Math.abs(transactionDate - appealAt);
              const TransactionDayDiff = diffInMs / (1000 * 60 * 60 * 24);

              if (TransactionDayDiff !== 0) {
                appeal_result = true;
              }

              const dateNow = new Date();
              const DateNowDiffInMs = Math.abs(dateNow - appealAt);
              const dateNowDiff = DateNowDiffInMs / (1000 * 60 * 60 * 24);
              const allowedDate = cloneDeep(appealAt);
              if (dateNowDiff < 1) {
                allowedDate.setDate(appealAt.getDate() + 1);
              }

              return (
                <div className="relative border-b pb-2 text-black">
                  <div
                    key={index}
                    className="flex  cursor-pointer justify-between items-center my-5 mx-3"
                    onClick={() => {
                      props.updateWithdrawalActiveId(get(i, "_id"));
                      props.updateTabActiveId(statusNumber.toString());
                      navigate(routes.withdrawalDetails.to());
                    }}
                  >
                    <span className=" inline-block w-2/3">
                      USDT <br />
                      {t("withdrawal", sourceKey.asset)}{" "}
                      {t(status, sourceKey.asset)}
                      {statusNumber !== 0 && appeal_result === true ? (
                        <> ({t("appealed", sourceKey.asset)})</>
                      ) : (
                        ""
                      )}
                      {statusNumber === 0 && appeal_result === true ? (
                        <> ({t("appealInProgress", sourceKey.asset)})</>
                      ) : (
                        ""
                      )}
                      <br />
                      {get(i, "transactionDate")
                        ? getFormatedDate(i?.transactionDate, true)
                        : ""}
                    </span>
                    <span className=" inline-block w-2/3">
                      <div className="flex justify-end items-center">
                        {formatNumber(get(i, "amount"), null, true, 4, true)}{" "}
                      </div>
                      <div className="flex justify-end items-center flex-wrap">
                        {_renderType(get(i, "status"), i)}
                      </div>
                    </span>
                  </div>
                  {statusNumber === 0 && (
                    <div className="absolute bottom-0 right-0 mb-3 ...">
                      {dateNowDiff < 1 && appeal_result === true ? (
                        t("appealed", sourceKey.asset)
                      ) : (
                        <Button
                          onClick={() => {
                            if (TransactionDayDiff === 0) {
                              setAppeal(true);
                              setAppealData(i);
                            } else {
                              if (dateNowDiff < 1) {
                                message.error(
                                  `${t("appealDesc", sourceKey.asset)} ${moment(
                                    allowedDate
                                  ).format("DD/MM/YYYY hh:mm:ss A")} `,
                                  4
                                );
                              } else {
                                setAppeal(true);
                                setAppealData(i);
                              }
                            }
                          }}
                        >
                          {t("appeal", sourceKey.asset)}
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </Scrollbars>

        {total_pages !== 1 && total_pages !== 0 && (
          <div style={{ textAlign: "right" }}>
            <LeftOutlined
              style={{ marginRight: 20, cursor: "pointer" }}
              onClick={() => {
                if (statusNumber === 0) {
                  if (page > 1) {
                    setPage(page - 1);
                  } else {
                    message.error(t("inFirstPage", sourceKey.asset));
                  }
                } else if (statusNumber === 1) {
                  if (rejectedPage > 1) {
                    setRejectedPage(rejectedPage - 1);
                  } else {
                    message.error(t("inFirstPage", sourceKey.asset));
                  }
                } else {
                  if (approvedPage > 1) {
                    setApprovePage(approvedPage - 1);
                  } else {
                    message.error(t("inFirstPage", sourceKey.asset));
                  }
                }
              }}
            />
            {`${current_page} out of ${total_pages} pages`}
            <RightOutlined
              style={{ marginLeft: 20, cursor: "pointer" }}
              onClick={() => {
                if (statusNumber === 0) {
                  if (page !== total_pages && total_pages !== 0) {
                    setPage(page + 1);
                  } else {
                    message.error(t("inLastPage", sourceKey.asset));
                  }
                } else if (statusNumber === 1) {
                  if (rejectedPage !== total_pages && total_pages !== 0) {
                    setRejectedPage(rejectedPage + 1);
                  } else {
                    message.error(t("inLastPage", sourceKey.asset));
                  }
                } else {
                  if (approvedPage !== total_pages && total_pages !== 0) {
                    setApprovePage(approvedPage + 1);
                  } else {
                    message.error(t("inLastPage", sourceKey.asset));
                  }
                }
              }}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <NavHeader
            suffix={
              <div className="justify-end flex items-center">
                {t("withdrawRecord", sourceKey.asset)}
              </div>
            }
            showBack={true}
            onBack={() => {
              props.updateTabActiveId("0");
              navigate(-1);
            }}
          >
            <Tabs
              centered
              defaultActiveKey={props.product.tabActiveId}
              style={{ color: "black" }}
            >
              <Tabs.TabPane
                tab={
                  <div className="text-center ml-5 text-xs sm:text-base">
                    {t("pending", sourceKey.asset)}
                  </div>
                }
                key={"0"}
              >
                {withdrawalDispaly(withdrawList, "pending")}
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <div className="text-center text-xs sm:text-base">
                    {t("approved", sourceKey.asset)}
                  </div>
                }
                key={"2"}
              >
                {withdrawalDispaly(approvedList, "approved")}
              </Tabs.TabPane>

              <Tabs.TabPane
                tab={
                  <div className="text-center mr-5 text-xs sm:text-base">
                    {t("rejected", sourceKey.asset)}
                  </div>
                }
                key={"1"}
              >
                {withdrawalDispaly(rejectedList, "rejected")}
              </Tabs.TabPane>
            </Tabs>
            <Modal
              title={t("confirmAppeal", sourceKey.asset)}
              visible={appeal}
              onCancel={() => {
                setAppeal(false);
              }}
              okText="Yes"
              okType="danger"
              onOk={() => {
                appealWithdrawal(appealData);
              }}
            >
              {t("appealWithdrawal", sourceKey.asset)}
              {get(appealData, "amount")} USDT?
            </Modal>
          </NavHeader>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  product: state.product,
});

const mapDispatchToProps = {
  loginSuccessful,
  triggerModal,
  updateWithdrawalActiveId,
  updateTabActiveId,
};
export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalList);
